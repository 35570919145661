import Navbar from "../components/Navbar";
import "../styles/Dashboard.css";
import { GoGear } from "react-icons/go";
import { BarChart, DonutChart } from "@mantine/charts";
import {
  FaChevronDown,
  FaLocationCrosshairs,
  FaRegClock,
  FaTemperatureFull,
} from "react-icons/fa6";
// import { VscGraph } from "react-icons/vsc";
import { IoReload } from "react-icons/io5";
import { PiHouseSimpleBold } from "react-icons/pi";
import { WiHumidity } from "react-icons/wi";
import { TbArrowWaveRightUp } from "react-icons/tb";
import Stations from "../components/stations";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
// import { DonutChart } from "@mantine/charts";

export const data = [
  { month: 'February', Choba: 458, Mile_3 : 420, Aluu: 435 },
  { month: 'March', Choba: 430, Mile_3 : 380, Aluu: 410 },
 
];

const Dashboard = () => {
  const [showSettings, setShowSettings] = useState(false)
  const [emissions, setEmissions] = useState({})
  const [refreshInterval, setRefreshInterval] = useState(null);
  const [minute, setMinutes] = useState(null);
  const [timeLapse, setTimeLapse] = useState()
  const [dataSet, setDataSet] = useState("") 
  const [station, setStation] = useState({})
  const [histogram, setHistogram] = useState([])

  const donutChartData = [
    { name: "PPM", value: 200, color: "white" },
    { name: "NOPPM", value: 800, color: "#81b622" },
  ];

  let intervalId = null;


  useEffect(() => {
    fetchData();

    

    const savedInterval = localStorage.getItem("refreshInterval");
    if (savedInterval) {

      if(intervalId){
        clearInterval(intervalId)
      }

      intervalId = setInterval(fetchData, savedInterval * 60 * 1000)
      setMinutes(savedInterval)
      setRefreshInterval(savedInterval);
    }else{
      setRefreshInterval(0)
    }

  }, [refreshInterval]);

  const getAverageData = (value) => {
    setDataSet(value)


    axios
      .get(`https://haxellsolutions.com/ecovisioniot/api/readings/time?time=${value}`)
      .then((res) => {
        console.log(res);
        setEmissions(res.data.emissions);
        setTimeLapse(res.data.time_lapse)
        setHistogram(res.data.histogram?.map(obj => {
          let timeComponents = obj.time.split(' ')[1].split(':'); 
          obj.time = timeComponents[0] + ':' + timeComponents[1]; 
          return obj;
        }))
      })
      .catch((err) => {
        console.log(err);
      });


  }

  const fetchData = () => {
    axios
      .get("https://haxellsolutions.com/ecovisioniot/api/readings")
      .then((res) => {
        console.log(res);
        // toast.success(res.data.message)
        setEmissions(res.data.emissions);
        setStation(res.data.emissions.station);

        setHistogram(res.data.histogram?.map(obj => {
          let timeComponents = obj.time.split(' ')[1].split(':'); 
          obj.time = timeComponents[0] + ':' + timeComponents[1]; 
          return obj;
        }));
        setTimeLapse(res.data.time_lapse);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const startTimer = (minutes) => {
    setShowSettings(false)
    if(intervalId || refreshInterval !== 0){
      clearInterval(intervalId) 
    };
    setRefreshInterval(minutes); 

    intervalId = setInterval(fetchData, minutes * 60 * 1000)
    localStorage.setItem("refreshInterval", minutes); 
    setMinutes(minutes)
  };

  return (
    <>
      <Navbar />

      <div className="wrapper">
        <div className="heading">
          <div className="leftHeading">
            <h3>Co2 Readings</h3>
            <p>
              <span>
                <FaLocationCrosshairs /> It is {emissions.temp_celsius} deg celsius at {emissions.city}
              </span>
              {/* <span>Outdoor air quality is good AQT 17/100</span>
              <span>bonne AQT 17/100</span> */}
            </p>
          </div>

          <div className="rightHeading">
            <div className="tabs">
              <span onClick={() => getAverageData("DAY")} className={dataSet === "DAY" ? "active" : undefined}>DAILY</span>
              <span onClick={() => getAverageData("WEEK")} className={dataSet === "WEEK" ? "active" : undefined}>WEEKLY</span>
              <span onClick={() => getAverageData("MONTH")} className={dataSet === "MONTH" ? "active" : undefined}>MONTHLY</span>
            </div>

            <button onClick={() => fetchData()}>
              <IoReload />
            </button>
            <button className="refreshSettings" >
              {/* Advanced View <VscGraph /> */}
              <IoReload onClick={() => setShowSettings(!showSettings)}/><GoGear  onClick={() => setShowSettings(!showSettings)}/>
              <div className={showSettings ? "refreshGroup" : "hide"}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((minutes) => (
                <p key={minutes} className={`${minutes}` === minute  ? "active" : undefined} onClick={() => startTimer(minutes)}>
                  {minutes} minute
                </p>
              ))}
            </div>
            </button>
          </div>
        </div>

        <div className="co2details">
          <div className="leftCo2Details">
            <div className="statCards">
              <div className={emissions.co2 > 400 ? "ppmCard danger" : "ppmCard"}>
                <h3>CO2</h3>
                <div className="chart">
                  <DonutChart
                    paddingAngle={0}
                    data={donutChartData}
                    strokeWidth={0}
                    size={110}
                    thickness={15}
                    chartLabel={emissions.co2}
                  />
                </div>
                <div className="info">
                  <h3>Very Good</h3>
                  <p>
                    <FaRegClock /> {timeLapse}
                  </p>
                  <div className="infoCircle">
                    <PiHouseSimpleBold />
                    <p>Outdoor</p>
                    <h2>17</h2>
                    <p>AQI</p>
                  </div>
                </div>
              </div>

              <div className="measurementCards">
                <div className="card">
                  <div className="topDetails">
                    <h2>Temperature</h2>
                    <p>Room Temperature</p>
                  </div>
                  <h1>
                     <div>{parseFloat(emissions.temp_celsius) ? parseFloat(emissions.temp_celsius).toFixed(2) : parseFloat(emissions.temp_celsius)} <span>C</span></div>
                    <span className="fahrenheit">{parseFloat(emissions.temp_fahrenheit) ? parseFloat(emissions.temp_fahrenheit).toFixed(2) : parseFloat(emissions.temp_fahrenheit)} F</span>
                  </h1>
                  <FaTemperatureFull />
                </div>
                <div className="card">
                  <div className="topDetails">
                    <h2>Humidity</h2>
                    <p>Water Vapor Concentration in air</p>
                  </div>
                  <h1>
                    <div>{parseFloat(emissions.humidity) ? parseFloat(emissions.humidity).toFixed(2) : parseFloat(emissions.humidity)} <span>%</span></div>
                  </h1>
                  <WiHumidity />
                </div>
                <div className="card">
                  <div className="topDetails">
                    <h2>Heat Index</h2>
                    <p>Heat Index in Celsius</p>
                  </div>
                  <h1>
                    <div>{parseFloat(emissions.heat_index_celsius)? parseFloat(emissions.heat_index_celsius).toFixed(2) : parseFloat(emissions.heat_index_celsius)} <span>C</span></div>
                    <span className="fahrenheit">{parseFloat(emissions.heat_index_fahrenheit) ? parseFloat(emissions.heat_index_fahrenheit).toFixed(2) : parseFloat(emissions.heat_index_fahrenheit)} F</span>
                  </h1>
                  <TbArrowWaveRightUp />
                </div>
              </div>
            </div>
            <div className="histogram">
              <div className="histogramHeading">
                <h3>Histogram</h3>
                <p>CO2 Concentration (ppm)</p>
              </div>

              <div className="chart">
                <BarChart
                  h={250}
                  data={histogram.sort(function(a, b) {
                    if (a.time < b.time) return -1;
                    if (a.time > b.time) return 1;
                    return 0;
                  })}
                  dataKey="time"
                  className={"chart"}
                  withLegend
                  series={[{ name: "co2", color: "#81b622" }]}
                />
              </div>
            </div>

            <div className="moreInformation">
              <div className="informationCard">
              <BarChart
                  h={300}
                  data={data}
                  dataKey="month"
                  xAxisLabel="Date"
                  yAxisLabel="Amount"
                  series={[
                    { name: 'Choba', color: 'lime' },
                    { name: 'Mile_3', color: 'blue.6' },
                    { name: 'Aluu', color: 'teal.6' },
                  ]}
                />
              </div>
              <div className="informationCard">
                <h2>More Information</h2>
                <div className="details">
                  <div className="left">
                    <h3>Best measure of the day</h3>
                    <span>21 Feb 2024 4:13pm</span>
                  </div>
                  <h3 className="ppm">
                    400 <span>ppm</span>
                  </h3>
                </div>
                <div className="details">
                  <div className="left">
                    <h3>Best measure of the day</h3>
                    <span>22 Feb 2024 08:13am</span>
                  </div>
                  <h3 className="ppm">
                    460 <span>ppm</span>
                  </h3>
                </div>
                <div className="details">
                  <div className="left">
                    <h3>Best measure of the day</h3>
                    <span>23 Feb 2024 01:26pm</span>
                  </div>
                  <h3 className="ppm">
                    425 <span>ppm</span>
                  </h3>
                </div>
              </div>

              <div className="informationCard indexCard">
                <h2>
                  ICONE index <span>12 Feb 2024 - 23 Feb 2024</span>
                </h2>
                <p className="indexInfo">
                  <span>Air Containemnt index</span>
                  <span>
                    Ranging from 0 (no containment to) 5 (extreme containment)
                  </span>
                </p>

                <div className="indexChart">
                  <span className="paleGreen">
                    <FaChevronDown /> 0
                  </span>
                  <span className="lightGreen">
                    {" "}
                    <FaChevronDown /> 1
                  </span>
                  <span className="blueShade">
                    {" "}
                    <FaChevronDown /> 2
                  </span>
                  <span className="yellow active">
                    {" "}
                    <FaChevronDown /> 3
                  </span>
                  <span className="orange">
                    {" "}
                    <FaChevronDown /> 4
                  </span>
                  <span className="red">
                    {" "}
                    <FaChevronDown /> 5
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="rightCo2Details">
            <Stations/>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Dashboard;
