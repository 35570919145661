import Navbar from "../components/Navbar";
import "../styles/Dashboard.css";
import "../styles/Charts.css";

import {

    FaLocationCrosshairs,

} from "react-icons/fa6";

import Footer from "../components/Footer";

// import { DonutChart } from "@mantine/charts";

const Charts = () => {
    return (
        <>
            <Navbar />

            <div className="wrapper">
                <div className="heading">
                    <div className="leftHeading">
                        <h3>Charts</h3>
                        <p>
                            <span>
                                <FaLocationCrosshairs /> CO2 Emission Forecast
                            </span>
                        </p>
                    </div>
                </div>

                <div className="imagesContainer">
                    <div className="imageBox">
                        <img
                            src={`${process.env.PUBLIC_URL}/output.png`}
                            alt="Output Chart 1"
                            className="image"
                        />
                        <h4>Prediction of CO2 for Mile 3</h4>
                    </div>
                    <div className="imageBox">
                        <img
                            src={`${process.env.PUBLIC_URL}/output1.png`}
                            alt="Output Chart 2"
                            className="image"
                        />
                        <h4>Prediction of CO2 for Choba</h4>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Charts;
