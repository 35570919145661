import { NavLink } from "react-router-dom";
import "../styles/Landing.css";
import { FaArrowRight, FaPlane } from "react-icons/fa6";
import Footer from "../components/Footer";
import { FaSunPlantWilt } from "react-icons/fa6";
import { AiOutlineFileSearch } from "react-icons/ai";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { GrGlobe } from "react-icons/gr";
import { MdOutlineMenuBook } from "react-icons/md";
import { WiSmoke } from "react-icons/wi";

const Landing = () => {
  return (
    <>
      <nav className="nav">
        <div className="logo">
          <img src={require("../assets/ecovision_logo.png")} alt="" />
        </div>
        <NavLink to={"/dashboard"}>
          Go to dashboard <FaArrowRight />
        </NavLink>
      </nav>

      <section className="heroSection">
        <div className="heroOverlay">
          <h1>EcovisionIOT</h1>
          <p>
          "IoT system for web-based monitoring and forecasting of greenhouse gas emissions in Port Harcourt, facilitating real-time insights to address environmental and health concerns linked to hydrocarbon-dependent growth.
          </p>
          <button onClick={(e) => {
      e.preventDefault();
      window.location.href='/dashboard';
      }}>Visit Dashboard</button>
        </div>
      </section>

      <section className="solutions">
        <div className="leftSection">
          <h3> IOT SYSTEM FOR WEB-BASED MONITORING AND
FORECASTING OF GREEN HOUSE GAS EMISSIONS</h3>
          <div className="content">
            <p>
            The proposed system addresses the pressing need to monitor greenhouse gas (GHG) emissions, particularly CO2 levels, in Port Harcourt, Nigeria, where socioeconomic development reliant on hydrocarbons has led to increased emissions and public health hazards. Utilizing specialized sensors and IoT technology, real-time data collection reveals significant CO2 emission increases in specific areas such as Choba, Garrison, and Mile-3, with corresponding percentages provided. 
            </p>
            <p>
            While the Real-Time Data Capture (RDC) system successfully detects low and high CO2 levels, predictive analysis using time-series models indicates a notable forecast error, underscored by Mean Absolute Percentage Error (MAPE) values, suggesting the necessity for further model refinement. This empirical examination highlights the urgency in addressing GHG emissions to mitigate environmental and health risks associated with industrial and commercial growth in PortHarcourt.
            </p>
          </div>
          <button  onClick={(e) => {
      e.preventDefault();
      window.open(require("../assets/Stanley_Project_Report.pdf"), '_blank');
      }}>View Report</button>
        </div>
        <div className="rightSection">
          <img src={require("../assets/laptop.png")} alt="" />
        </div>
      </section>

      <section className="features">
        <h2>Main Features of the IOT System</h2>

        <div className="cardWrapper">
          <div className="card">
            <FaSunPlantWilt />
            <div className="details">
              <h3>Real-Time Monitoring</h3>
              <p>
              Continuous monitoring of greenhouse gas emissions such as CO2, methane, and nitrous oxide, providing up-to-date data on environmental conditions.
              </p>
            </div>
          </div>

          <div className="card">
            <AiOutlineFileSearch />
            <div className="details">
              <h3>In-depth research</h3>
              <p>
                Sophisticated use of public and proprietary sources of data
                helps us to provide you with the most accurate estimats of your
                emissions
              </p>
            </div>
          </div>

          <div className="card">
            <IoShieldCheckmarkOutline />
            <div className="details">
              <h3>Data Security</h3>
              <p>
              Implementation of robust security measures to protect sensitive data, including encryption during transmission and access controls to prevent unauthorized access.
              </p>
            </div>
          </div>

          <div className="card">
            <GrGlobe />
            <div className="details">
              <h3>Forecasting Models</h3>
              <p>
              Implementation of time-series forecasting models to predict future greenhouse gas emission trends, aiding in decision-making and proactive measures to mitigate environmental impact.
              </p>
            </div>
          </div>

          <div className="card">
            <WiSmoke />
            <div className="details">
              <h3>Carbon footprint analysis</h3>
              <p>
                We go beyond reporting to help you optimize your emissions
                footprint with detailed analysis along all relevant dimensions.
              </p>
            </div>
          </div>

          <div className="card">
            <FaPlane />
            <div className="details">
              <h3>Actionable Insights</h3>
              <p>
                Our analysis will reveal emissions
                hot spots in the environment so that stakeholders can reduce 
                carbon footprint systematically.
              </p>
            </div>
          </div>

          <div className="card">
            <MdOutlineMenuBook />
            <div className="details">
              <h3>Reporting and Analysis</h3>
              <p>
              Generation of comprehensive reports and analysis tools to evaluate greenhouse gas emission patterns, compliance with regulations, and impact assessments on biodiversity and socioeconomic activities.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Landing;
