import { FaHeart, FaLocationPin } from "react-icons/fa6";
import "../styles/Stations.css";
import { IoReload } from "react-icons/io5";
import { TbDotsVertical } from "react-icons/tb";
import { useEffect, useState } from "react";
import axios from "axios"

const Stations = () => {
  const [stations, setStations] = useState([])
  useEffect(() => {
    axios.get("https://haxellsolutions.com/ecovisioniot/api/stations")
    .then(res => {
      // toast.success(res.data.message)
      setStations(res.data.stations)
    }).catch(err => {
      console.log(err)
    })
  }, [])
  return (
    <>
      <div className="stationWrapper">
        <h3>Stations</h3>
        <ul></ul>


        <div className="stationsWrap">
          <div className="stationsScroll">
            {
              stations.map((el) => {
                return(
                  <div className="stations" key={el.id}>
                    <div className="stationHeading">
                      <div className="stationLeftHeading">
                        <input type="checkbox" name="" id="" />
                        <h3>{el.name}</h3>
                      </div>
                    </div>

                    <p className="location">
                      <FaLocationPin /> {el.city}, {el.state}
                    </p>

                    <div className="stationStat">
                      <div className="stationStatGroup">
                        <span>
                          <FaHeart className={el.live === 1 ? "active" : undefined}/>&nbsp;{el.live === 1 ? "Connected" : "Not Connected"}
                        </span>
                        <span>
                          <IoReload /> {el.last_connected}
                        </span>
                      </div>
                      <span>
                        <TbDotsVertical />{" "}
                      </span>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

      </div>
    </>
  );
};

export default Stations;
