import "../styles/Navbar.css";
import { FaChevronDown } from "react-icons/fa6";
import { RiHomeLine,RiLineChartFill } from "react-icons/ri";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <nav>
        <div className="menu">
          <div className="logo"><img src={require("../assets/ecovision_logo.png")} alt="" /></div>
          <p>
            <RiHomeLine /> <span><Link to="/dashboard">Dashboard</Link></span>
          </p>
          <p>
          <RiLineChartFill /> <span><Link to="/charts"> Charts</Link></span>
          </p>
        </div>
        <div className="profile">
          <p>Admin</p>
          <div className="image">
            <img src={`${process.env.PUBLIC_URL}/user.png`} alt="" />
           
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
