import "../styles/Footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <p>Copyright &copy; 2023</p>
        <p>EcoVisionIoT.com  <span className="line"></span> designed by ORIMADIKE, OKECHUKWU STANISLAUS</p>
      </footer>
    </>
  );
};

export default Footer;
